/* eslint-disable react/prop-types */
import React from 'react';

const JSONBox = (props) => {
  const { json } = props;
  let obj;
  try {
    obj = JSON.parse(json);
  } catch (error) {
    return <></>;
  }

  const jsonResult = JSON.stringify(obj, null, 2);
  return (
    <pre>
      <code>{jsonResult}</code>
    </pre>
  );
};

export default JSONBox;
