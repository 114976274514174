import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth'
import { userSlice } from './user'

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    user: userSlice.reducer
  },
  devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true'
})