/* eslint-disable array-callback-return */
/* eslint-disable arrow-body-style */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from 'react';
import { useParams, NavLink as RouterLink } from 'react-router-dom';
import { 
    SvgIcon, 
    Card,
    Container, 
    Typography,
    Grid, 
    Stack, 
    TextField,
    Skeleton, 
    Button, 
    CircularProgress,
    FormHelperText,
    MenuItem,
    Select,
    Box,
    CardContent,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton
} from '@mui/material';
import moment from 'moment';
import numeral from 'numeral';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import { useApprover, useAuthSap, useAuthStore, usePurchases, useRequestApproval } from '../../../hooks';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import PRDocumentLineForm from '../../../components/forms/purchaseRequest/PRDocumentLineForm';
import Modal from '../../../components/modal/Modal';
import Label from '../../../components/label/Label';

const Headers = [
    { id: 'index', label: 'Línea', minWidth: 15 },
    { id: 'info', label: 'Articulos', minWidth: 200 },
    { id: 'info2', label: 'Proveedor', minWidth: 200 },
    { id: 'quantity', label: 'Cantidad', minWidth: 95 },
    { id: 'unit', label: 'Unidad Medida', minWidth: 135 },
    { id: 'currency', label: 'Moneda', minWidth: 90 },
    { id: 'price', label: 'Precio', minWidth: 100 },
    { id: 'tax', label: 'Impuesto', minWidth: 135 },
    { id: 'center', label: 'Centro Costo', minWidth: 125 },
    { id: 'options', label: '', minWidth: 50 }
];

function CalculateImports(lines) {
    let subtotal = 0;
    if (lines) {
        lines.map((line) => {
            const operacion = line.Quantity * line.UnitPrice;
            subtotal += operacion;
        });
        return numeral(subtotal).format(`$0,0.00`);
    }
}

export default function ApprovalDetail() {
    const { data } = useAuthStore();
    const { collectionWH, collectionCF, collectionCC, TaxesQuery, Warehouses, CashFlow, CenterCostBusiness } = useAuthSap();
    const { docEntry, docNum } = useParams();
    const { detailApproval } = useApprover();
    const { reqApprUpdate } = useRequestApproval();
    const [docRequest, setDocRequest] = useState(null);
    const [ taxes, setTaxes ] = useState();
    const [businessId, setBusinessId] = useState(0);
    const [loadWarehouses, setLoadWarehouses] = useState(true);
    const [loadCashFlow, setLoadCashFlow] = useState(true);
    const [loadCenterCost, setLoadCenterCost] = useState(true);
    const [business, setBusiness] = useState(localStorage.getItem('business'));
    const [formData, setFormData] = useState(null);
    const [open, setOpen] = useState(false);
    const [docLine, setDocLine] = useState(null);
    const [sendDocument, setSendDocument] = useState(false);
    const [isLoadAuth, setIsLoadAuth] = useState(false);
    const [isLoadRejec, setIsLoadRejec] = useState(false);
    const [isComment, setIsComments] = useState("");
    const [indx, setIndx] = useState(null);
    const [msgError, setMsgError] = useState(null);

    const fetchData = async () => {
        const { success, result } = await detailApproval(docEntry, docNum);
        await CashFlow();
        setLoadCashFlow(false);
        await Warehouses();
        setLoadWarehouses(false);
        await CenterCostBusiness(result.user_id, result.business_id);
        setLoadCenterCost(false);
        setBusinessId(result.business_id);
        const taxeResult = await TaxesQuery(result.business_id);
        setTaxes(taxeResult.result);
        setDocRequest(result);
        setIsComments(result.comments || "");
        setFormData(result.purchasing_requests);
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    const handleCloseModal = () => setOpen(false);
    
    const handleClickEdit = async (index, item) => {
        setIndx(index);
        setDocLine(item);
        // setFormData({...formData,DocumentLines: formData.DocumentLines.filter((doc, i) => i !== index)});
        setOpen(true);
    }

    const handleAddDoc = async (docs) => {
        const newDocLines = [...formData.DocumentLines];
        newDocLines[indx] = docs;
        setFormData({ ...formData, DocumentLines: newDocLines });
        handleCloseModal();
        // const newDocLine = [...formData.DocumentLines, docs];
        // setFormData({...formData, DocumentLines: newDocLine});
        // handleCloseModal();
    };

    const submitForm = async () => {
        setSendDocument(true);
        if (formData.DocumentLines.length === 0) {
            setSendDocument(false);
            setMsgError('Documento no cuenta con partidas');
        } else {
            const req = formData;
            // await UpdatePurchaseRequest(docEntry, formData);
            // setSendDocument(false);
        }
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        setIsComments(value);
    };

    const onAuthSubmit = async () => {
        setIsLoadAuth(true);
        try {
            const { success,result } = await reqApprUpdate(docEntry,{status: "autorizado"});
            await fetchData();
            setIsLoadAuth(false);
        } catch (error) {
            console.log(error);
            setMsgError(error.error);
            setIsLoadAuth(false);
        }
    };
    const onRejecubmit = async () => {
        setIsLoadRejec(true);
        try {
            const { success,result } = await reqApprUpdate(docEntry,{status: "rechazado", comments: isComment});
            await fetchData();
            setIsLoadRejec(false);
        } catch (error) {
            setIsLoadRejec(false);
        }
    };

    return (
        <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Stack alignItems="center" direction="row" spacing={2}>
                    <div>
                        <Typography variant="h4" >
                            Solicitud de Compra # {docRequest?.doc_num}
                        </Typography>
                    </div>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Button variant="contained" component={RouterLink} to={'/solicitudes-por-autorizar'}>
                        Regresar
                    </Button>
                </Stack>
            </Stack>
            {!docRequest && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Skeleton variant="rounded" height={180} />
                    </Grid>
                </Grid>
            )}
            {docRequest && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent>
                                {docRequest.status !== "autorizado" && (
                                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mb={2}>
                                        <Button disabled={docRequest.status === "autorizado"} sx={{mb: 1}} onClick={onAuthSubmit} variant="contained" startIcon={!isLoadAuth && (<Iconify icon="ic:outline-check-circle" />)}>
                                            {isLoadAuth ? (<CircularProgress sx={{color: "white"}} size={20} />):("Autorizar")}
                                        </Button> 
                                        <Button disabled={docRequest.status === "autorizado"} sx={{mb: 1}} onClick={onRejecubmit} variant="contained" startIcon={!isLoadRejec && (<Iconify icon="ic:outline-dangerous" />)}>
                                            {isLoadRejec ? (<CircularProgress sx={{color: "white"}} size={20} />):("Rechazar")}
                                        </Button> 
                                    </Stack>
                                )}
                                <Stack direction={{sm: "column", md: "row"}} alignItems="flex-start" justifyContent="space-between" mb={2}>
                                    <TextField 
                                        id="comment"
                                        disabled={docRequest.status === "autorizado"}
                                        name="comment"
                                        label="Comentario" 
                                        type="text" 
                                        variant="outlined" 
                                        multiline
                                        maxRows={4}
                                        fullWidth
                                        value={isComment}
                                        onChange={inputChange}
                                        // error={Boolean(errors.name)}
                                        // helperText={errors.name}
                                        autoComplete="off" 
                                    />
                                </Stack>
                            </CardContent>
                            <CardContent>
                                <Stack direction={{sm: "column", md: "row"}} alignItems="flex-start" justifyContent="space-between" mb={2}>
                                    <Typography gutterBottom variant="subtitle2">
                                        Fecha: {moment(docRequest.createdAt).format("DD/MM/YYYY")}
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle2">
                                        {docRequest.status === "autorizado" && (
                                            <Label color={'success'}>
                                                Autorizado
                                            </Label>
                                        )}
                                        {docRequest.status === "reproceso" && (
                                            <Label color={'info'}>
                                                Reproceso
                                            </Label>
                                        )}
                                        {docRequest.status === "pendiente" && (
                                            <Label color={'warning'}>
                                                Pendiente
                                            </Label>
                                        )}
                                        {docRequest.status === "rechazado" && (
                                            <Label color={'error'}>
                                                Rechazado
                                            </Label>
                                        )}
                                    </Typography>
                                </Stack>
                                {docRequest.comments && (
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                                        <Typography gutterBottom variant="subtitle2">
                                            Comentario: {docRequest.comments} 
                                        </Typography>
                                    </Stack>
                                )} 
                            </CardContent>
                            <Box sx={{ flexGrow: 1 }} />
                            <Divider sx={{borderBottom: '1px dashed #EBEDF3'}}/>
                            <CardContent>
                                <Scrollbar>
                                    <Table sx={{ minWidth: 700 }} size="small">
                                        <TableHead>
                                            <TableRow>
                                                {Headers.map((column) => (
                                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        {
                                            docRequest.purchasing_requests.DocumentLines.length > 0 ? (
                                                <TableBody>
                                                    {
                                                        formData?.DocumentLines.map((item, index) => {
                                                            const { CostingCode, ItemCode, TaxCode, nameVendor, RequiredDate,U_Comentarios: comentarios, ItemDescription, LineVendor, MeasureUnit, Quantity, UnitPrice, WarehouseCode, Currency} = item;
                                                            const iscentercost = collectionCC.find(x => x.center_cost.code === CostingCode);
                                                            console.log(collectionCC);
                                                            const isTax = taxes.find(x => x.Code === TaxCode);
                                                            return (
                                                                <Fragment key={index}>
                                                                    <TableRow key={index} hover>
                                                                        <TableCell>
                                                                            <Typography variant="body2">
                                                                                {index + 1}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">
                                                                                {ItemCode}
                                                                            </Typography>
                                                                            <Typography variant="caption" style={{
                                                                                margin: 0,
                                                                                lineHeight: 1.57143,
                                                                                fontSize: '0.75rem',
                                                                                fontFamily: '"Public Sans", sans-serif',
                                                                                fontWeight: 400,
                                                                                color: '#637381'
                                                                            }}>
                                                                                {ItemDescription}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">
                                                                                {LineVendor}
                                                                            </Typography>
                                                                            <Typography variant="caption" style={{
                                                                                margin: 0,
                                                                                lineHeight: 1.57143,
                                                                                fontSize: '0.75rem',
                                                                                fontFamily: '"Public Sans", sans-serif',
                                                                                fontWeight: 400,
                                                                                color: '#637381'
                                                                            }}>
                                                                                {nameVendor}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">
                                                                                {numeral(Quantity).format(`0,0.00`)}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">
                                                                                {MeasureUnit}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">
                                                                                {Currency}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography variant="body2">
                                                                                {numeral(UnitPrice).format(`$0,0.00`)}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Stack
                                                                                direction="column"
                                                                                justifyContent="center"
                                                                                alignItems="flex-start"
                                                                                spacing={0}
                                                                            >
                                                                                <Typography variant="body2">
                                                                                    {TaxCode}
                                                                                </Typography>
                                                                            </Stack>
                                                                            {isTax ? (
                                                                                <Typography variant="body2">
                                                                                    {isTax?.Name}
                                                                                </Typography>
                                                                            ):(
                                                                                <Skeleton variant="rounded" />
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Stack
                                                                                direction="column"
                                                                                justifyContent="center"
                                                                                alignItems="flex-start"
                                                                                spacing={0}
                                                                            >
                                                                                <Typography variant="body2">
                                                                                    {CostingCode}
                                                                                </Typography>
                                                                            </Stack>
                                                                            {iscentercost ? (
                                                                                <Typography variant="body2">
                                                                                    {iscentercost?.center_cost.description}
                                                                                </Typography>
                                                                            ):(
                                                                                <Skeleton variant="rounded" />
                                                                            )}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            {docRequest.status === "reproceso" && (
                                                                                <IconButton>
                                                                                    <SvgIcon onClick={() => handleClickEdit(index, item)}>
                                                                                        <Edit02Icon />
                                                                                    </SvgIcon>
                                                                                </IconButton>
                                                                            )}
                                                                                
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={10}>
                                                                            <Typography variant="body2">
                                                                                {comentarios}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                    <TableRow>
                                                        <TableCell align="right" colSpan={6}>
                                                            <Typography variant="body2">
                                                                Total: 
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" colSpan={1}>
                                                            <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                                                {CalculateImports(formData?.DocumentLines)}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right" colSpan={3}/>
                                                    </TableRow>
                                                </TableBody>
                                            ):null
                                        }
                                    </Table>
                                </Scrollbar>
                            </CardContent>
                            <Divider sx={{borderBottom: '1px dashed #EBEDF3'}}/>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box sx={{ alignItems: 'center', display: 'flex'}}>
                                            {msgError != null ? (
                                            <Typography color="error.main" variant="body2">
                                                {msgError}
                                            </Typography>
                                            ):null}
                                            <Box sx={{ flexGrow: 1 }} />
                                            {docRequest.status === "reproceso" && (
                                                <Button variant="contained" onClick={submitForm} disabled={sendDocument} startIcon={<Iconify icon="ic:outline-check-circle" />}>
                                                    Actualizar
                                                    {sendDocument && (
                                                        <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                                                    )}
                                                </Button> 
                                            )}
                                        </Box> 
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            <Modal title="Modificar Patida" maxWidth={'md'} open={open} onClose={handleCloseModal}>
                <PRDocumentLineForm business={business} docLine={docLine} startDate={docRequest?.DocDate} addDoc={handleAddDoc}/>
            </Modal>
        </Container>
    )
}
