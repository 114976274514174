/* eslint-disable consistent-return */
import { useDispatch, useSelector } from 'react-redux'; 
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { PostAuth, GetAuth } from '../service';
import { onLogin, onChecking, onLogout } from '../store/auth';
import { onUser } from '../store/user';

export const useAuthStore = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { status, data } = useSelector(state => state.auth);

    const login = async (body) => {
        dispatch(onChecking());
        try {
            const { data } = await PostAuth(body);
            localStorage.setItem('token', data.result);
            dispatch(onLogin(data.result));
        } catch (error) {
            localStorage.removeItem('uidx');
            localStorage.removeItem('session');
            localStorage.removeItem('token');
            dispatch(onLogout());
            enqueueSnackbar(`Correo y/o Contraseña incorrectas`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                action: (key) => (
                  <IconButton onClick={() => { closeSnackbar(key) }}>
                    <CloseIcon />
                  </IconButton>
                )
            });
        }
    }

    const logout = () => {
        localStorage.removeItem('uidx');
        localStorage.removeItem('session');
        localStorage.removeItem('token');
        dispatch(onLogout());
    }

    const checkAuthToken = async () => {
        dispatch(onChecking());
        try {
            const { data } = await GetAuth();
            dispatch(onLogin(data.result));
            localStorage.setItem('token', data.result.token);
            dispatch(onUser(data.result.user));
        } catch (error) {
            localStorage.removeItem('uidx');
            localStorage.removeItem('session');
            localStorage.removeItem('token');
            dispatch(onLogout());
        }
    }

    return {
        // Propiedades
        status,
        data,
        // Metodos
        login,
        logout,
        checkAuthToken,
    }
}