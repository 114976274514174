/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment } from 'react';
import {
    TextField,
    Button,
    IconButton,
    SvgIcon,
    Grid,
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
    CircularProgress,
    Typography,
    Box,
    Divider,
    FormHelperText,
    MenuItem,
    Select,
    Stack,
    Skeleton
} from '@mui/material';
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import Copy02Icon from '@untitled-ui/icons-react/build/esm/Copy02';
import numeral from 'numeral';
import { format } from 'date-fns';
import { useAuthSap, useAuthStore, useBusinessUser, usePurchases } from '../../../hooks';
import Iconify from '../../iconify';
import Scrollbar from '../../scrollbar';
import Modal from '../../modal';
import PRDocumentLineForm from './PRDocumentLineForm';

const Headers = [
    { id: 'info', label: 'Articulos', minWidth: 300 },
    { id: 'info2', label: 'Proveedor', minWidth: 300 },
    { id: 'quantity', label: 'Cantidad', minWidth: 95 },
    { id: 'unit', label: 'Unidad Medida', minWidth: 135 },
    { id: 'currency', label: 'Moneda', minWidth: 90 },
    { id: 'price', label: 'Precio', minWidth: 100 },
    { id: 'tax', label: 'Impuesto', minWidth: 200 },
    { id: 'center', label: 'Centro Costo', minWidth: 200 },
    { id: 'options', label: '', minWidth: 155 }
];

function CalculateImports(lines) {
    let subtotal = 0;
    lines.map((line, index) => {
        const operacion = line.Quantity * line.UnitPrice;
        subtotal += operacion;
    });
    return numeral(subtotal).format(`$0,0.00`);
}

export default function PurchaseRequestForm(props){
    const { onSubmit } = props;
    const { data } = useAuthStore();
    const { collectionWH, collectionCF, collectionCC, TaxesQuery, Warehouses, CashFlow, CenterCostBusiness } = useAuthSap();
    const { businessRelation, FindRelationBusinessUser } = useBusinessUser();
    const { docCreate, msgError, setMsgError, CreatePurchaseRequest } = usePurchases();
    const [startDate, setStartDate] = useState(new Date());
    const [ taxes, setTaxes ] = useState();
    const [formData, setFormData] = useState({
        Business: 0,
        ReqType: 171,
        Requester: data?.sap,
        ReqCode: data?.sap,
        DocDate: format(startDate, 'yyyy-MM-dd'),
        DocDueDate: format(startDate, 'yyyy-MM-dd'),
        TaxDate: format(startDate, 'yyyy-MM-dd'),
        RequriedDate: format(startDate, 'yyyy-MM-dd'),
        Comments: '',
        SendNotification: 'tNO',
        Document_ApprovalRequests: [{
            ApprovalTemplatesID: 3,
            Remarks: `ONCOMPRAS - SOLICITUD DE ${data?.sap}`
        }],
        DocumentLines:[]
    });
    const [business, setBusiness] = useState(null);
    const [docLine, setDocLine] = useState(null);
    const [open, setOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [page, setPage] = useState(0);
    const [sendDocument, setSendDocument] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => { 
        if (docCreate) {
            setFormData({
                SendNotification: 'tNO',
                ReqType: 171,
                Requester: data?.sap,
                ReqCode: data?.sap,
                DocDate: format(startDate, 'yyyy-MM-dd'),
                DocDueDate: format(startDate, 'yyyy-MM-dd'),
                TaxDate: format(startDate, 'yyyy-MM-dd'),
                RequriedDate: format(startDate, 'yyyy-MM-dd'),
                Comments: '',
                // DocCurrency: 'USD',
                Document_ApprovalRequests: [{
                    ApprovalTemplatesID: 3,
                    Remarks: `ONCOMPRAS - SOLICITUD DE ${data?.sap}`
                }],
                DocumentLines:[]
            }); 
        }
    }, [docCreate]);

    useEffect(() => {
        const fetchData = async () => {
          await FindRelationBusinessUser(data?.user?.id);
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await CenterCostBusiness(business);
            const taxeResult = await TaxesQuery(business);
            setTaxes(taxeResult.result);
        };
        if ( business ) fetchData();
    }, [business])
    

    const inputChange = (event) => {
        const { name, value } = event.target;
        let processedValue = value;
        if (name === 'DocDate') {
            processedValue = value;
            setFormData((prevState) => ({
                ...prevState,
                DocDueDate: processedValue,
                TaxDate: processedValue,
                RequriedDate: processedValue,
            }));
        } else if (name === 'Comments'){
            processedValue = value;
        } else if (name === 'Business'){
            processedValue = value;
            setBusiness(value);
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: processedValue,
        }));
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleOpenLine = async () => {
        if (formData.Business === 0) {
            setMsgError('Es requerido seleccionar la sociedad');
        } else {
            setDocLine(null); 
            setOpen(true);
        }
    }
    const handleAddDoc = async (docs) => {
        const newDocLine = [...formData.DocumentLines, docs];
        setFormData({...formData, DocumentLines: newDocLine});
        handleCloseModal();
    };
    const handleClickEdit = (index, item) => {
        setDocLine(item);
        setFormData({...formData,DocumentLines: formData.DocumentLines.filter((doc, i) => i !== index)});
        setOpen(true);
    };
    const handleClickCopy = (item) => {
        setDocLine(item);
        setOpen(true);
    };
    const handleClickDelete = async (index, item) => {
        setFormData({...formData,DocumentLines: formData.DocumentLines.filter((doc, i) => i !== index)});
        CalculateImports(formData.DocumentLines)
    };

    const submitForm = async () => {
        setSendDocument(true);
        if (formData.DocumentLines.length === 0) {
            setSendDocument(false);
            setMsgError('Documento no cuenta con partidas');
        } else {
            await CreatePurchaseRequest(formData);
            setSendDocument(false);
            setBusiness(0);
        }
    };

    return (
        <>
            <Box sx={{ p: 3 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={4}>
                        <Grid container spacing={1}>
                            {
                                businessRelation ?
                                <Grid item xs={12} sm={12} md={12}>
                                    <Select 
                                        autoFocus
                                        id="Business"
                                        name="Business"
                                        size="small"
                                        variant="outlined"
                                        onChange={inputChange}
                                        value={formData.Business}
                                        error={Boolean(errors.Business)}
                                        fullWidth
                                    >
                                        <MenuItem value={0}>Selecciona una sociedad</MenuItem>
                                        {
                                            businessRelation.map(
                                                (item, index) => (
                                                    <MenuItem key={index} value={item.business.id}>{item.business.businessName}</MenuItem>
                                                )
                                            )
                                        }
                                    </Select>
                                    <FormHelperText error>{errors.business}</FormHelperText>
                                </Grid>:null
                            }
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    id="DocDate"
                                    name="DocDate"
                                    label="Fecha Requerida"
                                    type="date"
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    onChange={inputChange}
                                    defaultValue={format(startDate, 'yyyy-MM-dd')}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <TextField 
                            id="Comments" 
                            name="Comments"
                            label="Comentarios"
                            type="text"
                            size="small" 
                            variant="outlined" 
                            fullWidth
                            onChange={inputChange}
                            value={formData.Comments}
                            error={Boolean(errors.Comments)}
                            helperText={errors.Comments}
                            multiline
                            rows={3}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ alignItems: 'center', display: 'flex'}}>
                            <Box sx={{ flexGrow: 1 }} />
                            <Button variant="contained" onClick={handleOpenLine} startIcon={<Iconify icon="eva:plus-fill" />}>
                                Agregar partida
                            </Button> 
                        </Box> 
                    </Grid> 
                </Grid>
                <Divider sx={{ my: 2, borderBottom: '1px dashed #EBEDF3' }}/>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Scrollbar>
                            <Table sx={{ minWidth: 700 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        {Headers.map((column) => (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {
                                    formData.DocumentLines.length > 0 ? (
                                        <TableBody>
                                            {
                                                formData.DocumentLines.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                                                    const { CostingCode, nameVendor, ItemCode, ItemDescription, LineVendor, MeasureUnit, TaxCode, Quantity, U_Comentarios, UnitPrice, WarehouseCode, Currency} = item;
                                                    const isTax = taxes.find(x => x.Code === TaxCode);
                                                    const iscentercost = collectionCC.find(x => x.center_cost.code === CostingCode);
                                                    return (
                                                        <Fragment key={index}>
                                                            <TableRow hover>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        {ItemCode}
                                                                    </Typography>
                                                                    <Typography variant="caption" style={{
                                                                        margin: 0,
                                                                        lineHeight: 1.57143,
                                                                        fontSize: '0.75rem',
                                                                        fontFamily: '"Public Sans", sans-serif',
                                                                        fontWeight: 400,
                                                                        color: '#637381'
                                                                    }}>
                                                                        {ItemDescription}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        {LineVendor}
                                                                    </Typography>
                                                                    <Typography variant="caption" style={{
                                                                        margin: 0,
                                                                        lineHeight: 1.57143,
                                                                        fontSize: '0.75rem',
                                                                        fontFamily: '"Public Sans", sans-serif',
                                                                        fontWeight: 400,
                                                                        color: '#637381'
                                                                    }}>
                                                                        {nameVendor}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        {numeral(Quantity).format(`0,0.00`)}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        {MeasureUnit}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        {Currency}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        {numeral(UnitPrice).format(`$0,0.00`)}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {isTax ? (
                                                                        <Stack
                                                                            direction="column"
                                                                            justifyContent="center"
                                                                            alignItems="flex-start"
                                                                            spacing={0}
                                                                        >
                                                                            <Typography variant="body2">
                                                                                {TaxCode}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {isTax.Name}
                                                                            </Typography>
                                                                        </Stack>
                                                                    ):(
                                                                        <Skeleton variant="rounded" />
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {iscentercost ? (
                                                                        <Stack
                                                                            direction="column"
                                                                            justifyContent="center"
                                                                            alignItems="flex-start"
                                                                            spacing={0}
                                                                        >
                                                                            <Typography variant="body2">
                                                                                {iscentercost.center_cost.code}
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {iscentercost.center_cost.description}
                                                                            </Typography>
                                                                        </Stack>
                                                                    ):(
                                                                        <Skeleton variant="rounded" />
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <IconButton>
                                                                        <SvgIcon onClick={() => handleClickEdit(index, item)}>
                                                                            <Edit02Icon />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                    <IconButton>
                                                                        <SvgIcon onClick={() => handleClickCopy(item)}>
                                                                            <Copy02Icon />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                    <IconButton onClick={() => handleClickDelete(index, item)}>
                                                                        <SvgIcon>
                                                                            <Trash02Icon />
                                                                        </SvgIcon>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell colSpan={10}>
                                                                    <Typography variant="body2">
                                                                        {U_Comentarios}
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    );
                                                })
                                            }
                                            <TableRow>
                                                <TableCell align="right" colSpan={5}>
                                                    <Typography variant="body2">
                                                        Total: 
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right" colSpan={1}>
                                                    <Typography variant="body2">
                                                        {CalculateImports(formData.DocumentLines)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right" colSpan={4}/>
                                            </TableRow>
                                        </TableBody>
                                    ):null
                                }
                            </Table>
                        </Scrollbar>
                    </Grid>
                </Grid> 
                <Divider sx={{ my: 2, borderBottom: '1px dashed #EBEDF3' }}/>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box sx={{ alignItems: 'center', display: 'flex'}}>
                            {msgError != null ? (
                            <Typography color="error.main" variant="body2">
                                {msgError}
                            </Typography>
                            ):null}
                            <Box sx={{ flexGrow: 1 }} />
                            <Button variant="contained" onClick={submitForm} disabled={sendDocument} startIcon={<Iconify icon="ic:outline-check-circle" />}>
                                Generar Documento
                                {
                                    sendDocument ? (
                                        <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                                    ):null
                                }
                            </Button> 
                        </Box> 
                    </Grid>
                </Grid>
            </Box>
            <Modal title="Nueva partida" maxWidth={'md'} open={open} onClose={handleCloseModal}>
                <PRDocumentLineForm business={formData?.Business} docLine={docLine} startDate={formData?.DocDate} addDoc={handleAddDoc}/>
            </Modal>
        </>
    )
}
