import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GetCenterCosts, FindCenterCostByBusiness, PostCenterCost, PutCenterCost } from '../../service';

export const useCenterCost = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [ centerCost, setCenterCost ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ loadingInfo, setLoadingInfo ] = useState(false);
    const [ sendResponse, setSendResponse ] = useState(false);

    async function centerCostData(){
        try {
            const { data } = await GetCenterCosts();
            setLoadingInfo(false);
            setLoading(false);
            setCenterCost(data.result);
        } catch (err) {
            setLoading(true);
        }
    }

    useEffect(() => {
        centerCostData();
    }, []);

    const createCenterCost = async (body) => {
        setLoading(true);
        await PostCenterCost(body).then(
            (result) => {
                const { data } = result;
                setLoading(false);
                setSendResponse(true);
                enqueueSnackbar(`${data.message}`, {
                variant: 'success',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                        <CloseIcon />
                    </IconButton>
                )
                });
            }
        ).catch(
            (error) => {
                setLoading(false);
                setSendResponse(false);
                enqueueSnackbar(`${error.response.data.error}`, {
                    variant: 'error',
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    action: (key) => (
                        <IconButton onClick={() => { closeSnackbar(key) }}>
                          <CloseIcon />
                        </IconButton>
                    )
                });
            }
        );
    }

    const updateCenterCost = async (id, body) => {
        setLoading(true);
        await PutCenterCost(id, body).then(
            (result) => {
                const { data } = result;
                setLoading(false);
                setSendResponse(true);
                enqueueSnackbar(`${data.message}`, {
                    variant: 'success',
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    action: (key) => (
                        <IconButton onClick={() => { closeSnackbar(key) }}>
                            <CloseIcon />
                        </IconButton>
                    )
                });
            }
        ).catch(
            (error) => {
                setLoading(false);
                setSendResponse(false);
                enqueueSnackbar(`${error.response.data.error}`, {
                    variant: 'error',
                    autoHideDuration: 3000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    action: (key) => (
                        <IconButton onClick={() => { closeSnackbar(key) }}>
                          <CloseIcon />
                        </IconButton>
                    )
                });
            }
        );
    }

    const FindBusinessCenterCost = async (id) => {
        await FindCenterCostByBusiness(id).then(
            (result) => {
                const { data } = result;
                setCenterCost(data.result);
            }
        ).catch(
          (error) => {
            enqueueSnackbar(`${error.response.data.error}`, {
              variant: 'error',
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              action: (key) => (
                <IconButton onClick={() => { closeSnackbar(key) }}>
                  <CloseIcon />
                </IconButton>
              )
            });
          }
        );
    }

    return { sendResponse, loading, loadingInfo, centerCost, centerCostData, createCenterCost, updateCenterCost, FindBusinessCenterCost }
}
