import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GetBusiness, PostBusiness, PutBusiness } from '../../service';

export const useBusiness = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ business, setBusiness ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ loadingInfo, setLoadingInfo ] = useState(false);
  const [ sendResponse, setSendResponse ] = useState(false);

  async function businessData(){
    try {
      const { data } = await GetBusiness();
      setLoadingInfo(false);
      setLoading(false);
      setBusiness(data.result);
    } catch (err) {
      setLoading(true);
    }
  }

  useEffect(() => {
    businessData();
  }, []);

  const CreateBusiness = async (body) => {
    setLoading(true);
    await PostBusiness(body).then(
      (result ) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        setSendResponse(false);
        enqueueSnackbar(`${error.response.data.error}`, {
            variant: 'error',
            autoHideDuration: 3000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            action: (key) => (
                <IconButton onClick={() => { closeSnackbar(key) }}>
                  <CloseIcon />
                </IconButton>
            )
        });
      }
    );
  }

  const UpdateBusiness = async (id, body) => {
    setLoading(true);
    await PutBusiness(id, body).then(
      (result ) => {
        const { data } = result;
        setLoading(false);
        setSendResponse(true);
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        setSendResponse(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  return { business, loading, loadingInfo, sendResponse, businessData, CreateBusiness, UpdateBusiness}
}

