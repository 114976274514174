/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
// @mui
import { Table, Paper, TableRow, TableBody, TableCell, Typography, TableContainer, TablePagination, Backdrop, CircularProgress } from '@mui/material';
// components
import Scrollbar from '../scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
import BodyUser from './bodyCustomer/BodyUser';
import BodyBusiness from './bodyCustomer/BodyBusiness';
import BodyCenterCost from './bodyCustomer/BodyCenterCost';
import BodyLog from './bodyCustomer/BodyLog';
import BodyPurchaseRequest from './bodyCustomer/BodyPurchaseRequest';
import BodyDrafts from './bodyCustomer/BodyDrafts';
import BodyApprovalPurchase from './bodyCustomer/BodyApprovalPurchase';
import BodyApprovalPurchaseAdmin from './bodyCustomer/BodyApprovalPurchaseAdmin';

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Parametro Especifico
  // function applySortFilter(array, comparator, query) {
  //   const stabilizedThis = array.map((el, index) => [el, index]);
  //   stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  //   });
  //   if (query) {
  //     return filter(array, (_user) => _user.company.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  //   }
  //   return stabilizedThis.map((el) => el[0]);
  // }
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return array.filter((obj) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const val = obj[key];
            if (typeof val === "string" || typeof val === "number") {
              const strVal = val.toString();
              if (strVal.toLowerCase().includes(query.toLowerCase())) {
                return true;
              }
            }
          }
        }
        return false;
      });
    }
    return stabilizedThis.map((el) => el[0]);
  }

export default function TableCustomer(props) {
  const {tableHead, refresh, data, typeTable} = props;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(tableHead[0].id);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ openBackdrop, setOpenBackdrop] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const filtered = applySortFilter(data, getComparator(order, orderBy), filterName);
  const isNotFound = !filtered.length && !!filterName; 

  const handleOpenBackdrop = (on) => {
    setOpenBackdrop(on);
  }

  return (
    <>
      <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {
                typeTable === 'users' && 
                (
                  <BodyUser filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh} openBackdrop={handleOpenBackdrop}/>
                )
              }
              {
                typeTable === 'business' && 
                (
                  <BodyBusiness filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh}/>
                )
              }
              {
                typeTable === 'centerCost' && 
                (
                  <BodyCenterCost filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh}/>
                )
              }
              {
                typeTable === 'logs' && 
                (
                  <BodyLog filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh}/>
                )
              }
              {
                typeTable === 'purchaseRequest' && 
                (
                  <BodyPurchaseRequest filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh}/>
                )
              }
              {
                typeTable === 'requestPending' && 
                (
                  <BodyApprovalPurchase filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh}/>
                )
              }
              {
                typeTable === 'drafts' && 
                (
                  <BodyDrafts filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh}/>
                )
              }
              {
                typeTable === 'request-documents' && 
                (
                  <BodyApprovalPurchaseAdmin filtered={filtered} page={page} rowsPerPage={rowsPerPage} emptyRows={emptyRows}  refresh={refresh}/>
                )
              }
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Sin resultados
                      </Typography>
                      <Typography variant="body2">
                        No se han encontrado resultados para &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Intente verificar errores tipográficos o usar palabras completas.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        labelRowsPerPage={"Registro por página:"}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Backdrop sx={{ color: '#fff', zIndex: 100 }} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
