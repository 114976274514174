/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react'
import { useSnackbar } from 'notistack';
import { styled, Button, Paper, Grid, Chip, MenuItem, Select, CircularProgress, IconButton, Skeleton, Stack, FormHelperText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useBusiness, useCenterCost, useCenterCostUser } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function CenterCostUserForm(props){
    const { data } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { centerCost, FindBusinessCenterCost } = useCenterCost();
    const { business } = useBusiness();
    const { centerCostRelation, loading, skeleting, RelationCenterCostUser, FindRelationCenterCostUser, DeleteRelationCenterCostUser } = useCenterCostUser();
    const [ selectedIndex, setSelectedIndex ] = useState(-1);
    const [ formData, setFormData ] = useState({
        user: data?.id, 
        business: 0, 
        centerCost: 0
    });
    const [ btn, setBtn ] = useState('Añadir');
    const [ errors, setErrors ] = useState([]);
    
    useEffect(() => {
        FindRelationCenterCostUser(data?.id);
    }, []);

    const CenterCostUserSchema = yup.object().shape({
        centerCost: yup
            .number()
            .oneOf(centerCost.map(item => item.id), 'Selecciona un centro de costo')
            .required('Centro de costo es requerida'),
        business: yup
            .number()
            .oneOf(business.map(item => item.id), 'Selecciona una empresa')
            .required('La empresa es requerida'),
    });

    const inputChange = async (event) => {
        const { name, value } = event.target;
        let processedValue = value;

         if (name === 'business') {
            processedValue = value;
            await FindBusinessCenterCost(value);
            setFormData((prevState) => ({...prevState, centerCost: 0}));
        } else if (name === 'centerCost') {
            processedValue = value;
        }

        setFormData((prevState) => ({
            ...prevState,
            [name]: processedValue,
        }));
        CenterCostUserSchema.validateAt(name, { [name]: processedValue })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((err) => {
            setErrors({ ...errors, [name]: err.errors[0] });
        });
    };
    
    const addCenterCostInUser = async () => {
        let exit = false;
        setBtn('Añadiendo');
        
        centerCostRelation.forEach(cc => {
            if (cc.center_cost_id === formData.centerCost){
                exit = true;
            }
        });

        if (exit) {
            setBtn('Añadir');
            enqueueSnackbar(`Centro de Costo, ya ha sido relacionado!`,{
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            return;
        }

        try {
            await CenterCostUserSchema.validate(formData, { abortEarly: false });
            if (!formData.centerCost) {
                setBtn('Añadir');
                return;
            }
            await RelationCenterCostUser(formData);
            FindRelationCenterCostUser(data.id);
            setBtn('Añadir');
        } catch (err) {
            enqueueSnackbar(`Formulario incompleto!`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            if (err instanceof yup.ValidationError) {
                setErrors(err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {}));
            }
            setBtn('Añadir');
        }
    };

    const centerCostDelete = async (id, index) => {
        setSelectedIndex(index);
        await DeleteRelationCenterCostUser(id);
        await FindRelationCenterCostUser(data.id);
        setSelectedIndex(-1);
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Item>
                    <Select 
                        autoFocus
                        id="business"
                        name="business"
                        size="small"
                        variant="outlined"
                        onChange={inputChange}
                        value={formData.business}
                        error={Boolean(errors.business)}
                        fullWidth
                    >
                        <MenuItem value={0}>Selecciona una empresa</MenuItem>
                        {
                            business.map(
                                (item, index) => (
                                    <MenuItem key={index} value={item.id}>{item.businessName}</MenuItem>
                                )
                            )
                        }
                    </Select>
                    <FormHelperText error>{errors.business}</FormHelperText>
                </Item>
            </Grid>
            {
                formData.business !== 0 ?
                <Grid item xs={12} sm={12} md={12}>
                    <Item>
                        <Select 
                            id="centerCost"
                            name="centerCost"
                            size="small"
                            variant="outlined"
                            onChange={inputChange}
                            value={formData.centerCost}
                            error={Boolean(errors.centerCost)}
                            fullWidth
                        >
                            <MenuItem value={0}>Selecciona un centro costo</MenuItem>
                            {
                                centerCost.map(
                                    (item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.code} - {item.description}</MenuItem>
                                    )
                                )
                            }
                        </Select>
                        <FormHelperText error>{errors.centerCost}</FormHelperText>
                    </Item>
                </Grid>:null
            }
            <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                <Item>
                    <Button variant="contained" onClick={addCenterCostInUser}>
                        {btn}
                        {
                            loading ? (
                                <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                            ):(
                                ''
                            )
                        }
                    </Button>   
                </Item>
            </Grid>
            <Grid item xs={12}>
                {
                    skeleting ? 
                        <Stack spacing={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Skeleton variant="rounded" width={200} height={40} />
                        </Stack>
                    :
                    <>
                        {
                            centerCostRelation.map(
                                (item, index) => (
                                    <Grid item xs={12} key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        {selectedIndex === index ? (
                                            <Chip 
                                                variant="outlined" 
                                                label={`${item.center_cost.code} - ${item.center_cost.description}`}
                                                color="error"
                                                avatar={selectedIndex ? <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/> : null} 
                                            />
                                        ) : (
                                            <Chip 
                                                variant="outlined" 
                                                style={{margin: '5px'}}
                                                label={`${item.center_cost.code} - ${item.center_cost.description}`} 
                                                onDelete={() => centerCostDelete(item.id, index)} 
                                            />
                                        )}
                                    </Grid>
                                )
                            )
                        }
                    </>
                }
            </Grid>
        </Grid>
    )
}
