import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ApproversAll, UserApproverFind, UserApproverRelation, UserApproverDelete } from '../../service';

export const useUserApprover = () =>{
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ userApprovers, setUserApprovers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ skeleting, setSkeleting ] = useState(false);

  const inApprovers = async () => {
    try {
        const { data:{ success, message, result } } = await ApproversAll();
        return { success, result };
    } catch (error) {
        return error;
    }
  }

  const RelationUserApprover = async (body) => {
    setLoading(true);
    await UserApproverRelation(body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        enqueueSnackbar(`${data.message}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const FindUserApprovers = async (id) => {
    try {
        const { data:{ success, message, result } } = await UserApproverFind(id);
        return { success, result };
    } catch (error) {
        return error;
    }
  }

  const DeleteApproverUser = async (id) => {
    try {
        const { data:{ success, message, result } } = await UserApproverDelete(id);
        return { success, result };
    } catch (error) {
        return error;
    }
  }

  return {
    userApprovers,
    loading,
    skeleting,
    inApprovers,
    FindUserApprovers,
    RelationUserApprover,
    DeleteApproverUser
  }
}

