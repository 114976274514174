import { Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage, HomePage, UserPage, Page404, ProfilePage, BusinessPartnerPage, CenterCostPage, LogPage, PurchaseRequestPage, HistoryPage, PurchaseDetailPage, ApprovalPage, ApprovalDetail, DocumentPage } from '../pages';
import { PrivateRoute, PublicRoute } from './config';
import { AuthGuard, AuthRole } from '../guards';

function Router(){
    return (
        <Routes>
            <Route path="/" element={<Navigate to={PrivateRoute.HOME} />}/>
            <Route path={PublicRoute.LOGIN} element={<LoginPage />}/>
            <Route element={<AuthGuard />}>
                <Route path={PrivateRoute.HOME} element={<HomePage />}/>
                <Route path={PrivateRoute.PROFILE} element={<ProfilePage />}/>
                <Route path={PrivateRoute.PURCHASEREQUEST} element={<PurchaseRequestPage />}/>
                <Route path={PrivateRoute.HISTORY} element={<HistoryPage />}/>
                <Route path={`${PrivateRoute.HISTORY}/${PrivateRoute.PURCHASEREQUESTDETAIL}/:docEntry/:docNum`} element={<PurchaseDetailPage />}/>

                <Route element={ <AuthRole roles={['develop', 'admin']} /> }>
                    <Route path={PrivateRoute.LOGGER} element={<LogPage />}/>
                </Route>

                <Route element={ <AuthRole roles={['develop', 'admin', 'client']} /> }>
                    <Route path={PrivateRoute.BUSINESS} element={<BusinessPartnerPage />}/>
                    <Route path={PrivateRoute.USERS} element={<UserPage />}/>
                    <Route path={PrivateRoute.CENTERCOST} element={<CenterCostPage />}/>
                    <Route path={PrivateRoute.DOCUMENTS} element={<DocumentPage />}/>
                </Route>

                <Route element={ <AuthRole roles={['develop', 'admin', 'client', 'approval']} /> }>
                    <Route path={PrivateRoute.REQUESTPENDING} element={<ApprovalPage />}/>
                    <Route path={`${PrivateRoute.REQUESTPENDING}/${PrivateRoute.REQUESTPENDINGDETAIL}/:docEntry/:docNum`} element={<ApprovalDetail />}/>
                </Route>
                
                <Route path={PrivateRoute.UNI} element={<Page404 />}/>
            </Route>
        </Routes>
    );
}

export default Router;