/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Card, CardActions, Avatar, Stack, Container, Typography, Box, Tab, Tabs, } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { UserProfile, UserBusiness } from './components';
import { useUsers } from '../../hooks';
import UserCenterCost from './components/UserCenterCost/UserCenterCost';
import { renderName, renderRole } from '../../utils';

export default function ProfilePage() {
  const { user, FindUser } = useUsers();
  const [ value, setValue ] = useState('1');

  const handleChange = (event, newValue ) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (user != null) {
      FindUser(user?.id);
    }
  }, []);
  
  return (
    <Container maxWidth="xl">
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Perfil del Usuario
        </Typography>
      </Stack> */}
      <Stack>
        <Card elevation={0}>
          <Box p={2} style={{backgroundColor: '#1bc5bd6b'}}>
            <Box display="flex" alignItems="center" style={{position: 'relative', top: 30}}>
              <Avatar ml={2} alt={Object.keys(user).length === 0 ? '':user?.name} sx={{ width: 100, height: 100 }}>
                {Object.keys(user).length === 0 ? '':user?.name.substring(0, 1)}
                {Object.keys(user).length === 0 ? '':user?.lastNameP.substring(0, 1)}
              </Avatar>
              <Box ml={2}>
                <Typography variant="h4">
                  {renderName(user.name, user.lastNameP)}
                </Typography>
                <Typography variant="body1">
                  {renderRole(user.role)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <CardActions>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              {/* <TabContext value={value}>
                <TabList onChange={handleChange} style={{float: "right"}} variant="scrollable" scrollButtons="auto" >
                  <Tab label="Perfil" value="1" />
                  <Tab label="Centro de Costo" value="2" />
                  <Tab label="Empresas" value="3" />
                </TabList>
              </TabContext> */}
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                style={{float: "right"}}
              >
                <Tab label="Perfil" value="1" />
                <Tab label="Centro de Costo" value="2" />
                <Tab label="Empresas" value="3" />
              </Tabs>
            </Box>
          </CardActions>
        </Card>
      </Stack>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <TabPanel value="1">
            <UserProfile usr={user} />
          </TabPanel>
          <TabPanel value="2">
            <UserCenterCost user={user} />
          </TabPanel>
          <TabPanel value="3">
            <UserBusiness user={user} />
          </TabPanel>
        </TabContext>
      </Box>
    </Container>
  )
}
