/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { TableRow, TableCell, Tooltip, SvgIcon, IconButton, CircularProgress } from '@mui/material';
import moment from 'moment';
import ArrowRight from '@untitled-ui/icons-react/build/esm/ArrowRight';
import Label from '../../label';

export default function BodyDrafts(props){
  const { filtered, page, rowsPerPage, emptyRows } = props;
  const [ openSP, setOpenSP ] = useState(false);
  const [ docEntry, setDocEntry ] = useState(null);

  const handleSolped = async (doc) => {
    setOpenSP(true);
    setDocEntry(doc.DocEntry);
  }

  return (
    <>
      {
        filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
          const { id, uuid, createdAt, comments, status } = row;
          return (
            <TableRow hover key={index} tabIndex={-1}>
                <TableCell align="left">
                  {id}
                </TableCell>
                <TableCell align="left">
                  {uuid}
                </TableCell>
                <TableCell align="left">
                  {moment(createdAt).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="left">
                  {comments || "Sin comentarios"}
                </TableCell>
                <TableCell align="left">
                  {
                    status === 'autorizado' ? 
                      <Label color={'success'}>
                        Aprobado
                      </Label>
                    : status === 'pendiente' ?
                      <Label color={'warning'}>
                        Pendiente
                      </Label>
                    : status === 'reproceso' ?
                      <Label color={'warning'}>
                        Reprocesar
                      </Label>
                    :
                      <Label color={'error'}>
                        Rechazado
                      </Label>
                  }
                </TableCell>
                <TableCell align="right">
                  {
                    status === 'pendiente' || status === 'rechazado' ? 
                    <Tooltip title={'Detalle solicitud'} arrow>
                      <IconButton onClick={() => handleSolped(row)} component={RouterLink} to={`detalle-solicitud/${id}/${uuid}`}>
                        <SvgIcon>
                          <ArrowRight />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>:null
                  }
              </TableCell>
            </TableRow>
          );
        })
      }

      {
        emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={7} />
          </TableRow>
        )
      }
  </>
  )
}

