/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import {
    styled,
    TextField,
    Button,
    Paper,
    Grid,
    CircularProgress,
    IconButton
} from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { BusinessSchema } from '../../../utils/validacionSchema';
import { useBusiness } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function BusinessForm(props){
    const { business, onSubmit } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { loading, sendResponse, CreateBusiness, UpdateBusiness } = useBusiness();
    const [ formData, setFormData ] = useState({ 
        businessName: business?.businessName || '', 
        rfc: business?.rfc || '',
        zipCode: business?.zipCode || '',
        street: business?.street || '',
        suburb: business?.suburb || '',
        municipality: business?.municipality || '',
    });
    const [ errors, setErrors ] = useState([]);

    useEffect(() => { 
        if (sendResponse) {
            onSubmit(true); 
        }
    }, [sendResponse, onSubmit]);

    const inputChange = (event) => {
        const { name, value } = event.target;
        let processedValue = value;

        if (name === 'businessName') {
            processedValue = value.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
        } else if (name === 'rfc') {
            processedValue = value.toUpperCase();
        } else if (name === 'zipCode') {
            processedValue = value.toUpperCase();
        } else if (name === 'street') {
            processedValue = value.toUpperCase();
        } else if (name === 'suburb') {
            processedValue = value.toUpperCase();
        } else if (name === 'municipality') {
            processedValue = value.toUpperCase();
        }
        setFormData((prevState) => ({
            ...prevState,
            [name]: processedValue,
        }));
        BusinessSchema.validateAt(name, { [name]: processedValue })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((err) => {
            setErrors({ ...errors, [name]: err.errors[0] });
        });
    };

    const submitForm = async () => {
        try {
            await BusinessSchema.validate(formData, { abortEarly: false });
            if (business == null) {
                await CreateBusiness(formData);
            } else {
                await UpdateBusiness(business.id, formData);
            }
        } catch (err) {
            enqueueSnackbar(`Formulario incompleto!`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            if (err instanceof yup.ValidationError) {
                setErrors(err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {}));
            }
        }
    }

  return (
    <Grid container>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    autoFocus 
                    id="businessName"
                    name="businessName"
                    label="Razón Social" 
                    type="text" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    onChange={inputChange}
                    value={formData.businessName}
                    error={Boolean(errors.businessName)}
                    helperText={errors.businessName}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    id="rfc" 
                    name="rfc"
                    label="RFC"
                    type="text"
                    size="small" 
                    variant="outlined" 
                    fullWidth
                    disabled={ business?.rfc == null ? false:true }
                    onChange={inputChange}
                    value={formData.rfc}
                    error={Boolean(errors.rfc)}
                    helperText={errors.rfc}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    id="zipCode"
                    name="zipCode"
                    label="Código Postal" 
                    type="text" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    onChange={inputChange}
                    value={formData.zipCode}
                    error={Boolean(errors.zipCode)}
                    helperText={errors.zipCode}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    id="street"
                    name="street"
                    label="Direccion (Opcional)" 
                    type="text" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    onChange={inputChange}
                    value={formData.street}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    id="suburb"
                    name="suburb"
                    label="Colonia (Opcional)" 
                    type="text" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    onChange={inputChange}
                    value={formData.suburb}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <Item>
                <TextField 
                    id="municipality"
                    name="municipality"
                    label="Municipio (Opcional)" 
                    type="text" 
                    size="small" 
                    variant="outlined" 
                    fullWidth 
                    onChange={inputChange}
                    value={formData.municipality}
                    autoComplete="off" />
            </Item>
        </Grid>
        <Grid item xs={12} style={{display:'flex', justifyContent:'flex-start'}}>
            <Item>
                <Button variant="contained" onClick={submitForm} disabled={loading}>
                    {business == null ? 'Registrar':'Actualizar'}
                    {
                        loading ? (
                            <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                        ):(
                            ''
                        )
                    }
                </Button>                   
            </Item>
        </Grid>
    </Grid>
  )
}
