import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <Helmet>
        <title>ON COMPRAS</title>
      </Helmet>
      <BrowserRouter>
        <SnackbarProvider maxSnack={1}>
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>
);
// serviceWorker.unregister();
reportWebVitals();
