/* eslint-disable react-hooks/rules-of-hooks */
import * as yup from 'yup';

export const RegisterSchema = yup.object().shape({
    name: yup
        .string()
        .min(2, 'Minimo, 2 carácteres')
        .required('Nombre(s) es requerido'),
    lastNameP: yup
        .string()
        .min(2, 'Minimo, 2 carácteres')
        .required('Apellido es requerido'),
    lastNameM: yup
        .string()
        .min(2, 'Minimo, 2 carácteres')
        .required('Apellido es requerido'),
        // .matches(/^([A-ZÑ&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/, 'Estructura de RFC no es valido'),
    email: yup
        .string()
        .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, 'Estructura del correo electrónico no es valido')
        .required('Correo electrónico es requerido'),
});

export const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/, 'Estructura del correo electrónico no es valido')
        .required('Correo electrónico es requerido'),
    password: yup
        .string()
        .min(7, 'Mínimo 7 carácteres')
        .required('Contraseña es requerida'),
});

export const BusinessSchema = yup.object().shape({
    rfc: yup
        .string()
        .matches(/^([A-ZÑ&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))((-)?([A-Z\d]{3}))?$/, 'Estructura de RFC no es valido')
        .required('RFC es requerido'),
    businessName: yup
        .string()
        .min(5, 'Mínimo, 5 carácteres')
        .required('Razón Social es requerida'),
    zipCode: yup
        .string()
        .min(5, 'Mínimo, 5 digitos')
        .required('Codigo Postal es requerido'),
    street: yup
        .string(),
    suburb: yup
        .string(),
    municipality: yup
        .string(),
});

export const BusinessUserSchema = yup.object().shape({
    codeEmploye: yup
        .string()
        .min(1, 'Mínimo, 1 carácter')
        .required('No.Empleado es requerida'),
    business: yup
        .string()
        .notOneOf([0], 'La empresa es requerida')
        .required('La empresa es requerida'),
});

export const CenterCostSchema = yup.object().shape({
    code: yup
        .string()
        .min(1, 'Mínimo, 1 carácter')
        .required('Código es requerido'),
    description: yup
        .string()
        .min(3, 'Mínimo, 3 carácteres')
        .required('Descripción es requerida'),
});