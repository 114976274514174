import React from 'react';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { PurchaseRequestForm } from '../../components/forms';

export default function PurchaseRequestPage() {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 5 }}>
        Solicitud de Compra
      </Typography>
      <Card>
        <CardContent>
          <PurchaseRequestForm />
        </CardContent>
      </Card>
    </Container>
  )
}
