// ----------------------------------------------------------------------

const account = {
  displayName: 'Ricardo Garcia',
  role: 'administrador',
  photoURL: '/assets/images/logos/on.png',
  // photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
