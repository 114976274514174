import React, { useState } from 'react';
import { Card, Stack, Grid, Button, Container, Typography, Skeleton, } from '@mui/material';
import Iconify from '../../components/iconify';
import Modal from '../../components/modal';
import TableCustomer from '../../components/table/TableCustomer';
import { useBusiness } from '../../hooks';
import { BusinessForm } from '../../components/forms';

const TABLE_HEAD = [
  { id: 'businessName', label: 'Razón Social', alignRight: false },
  { id: 'rfc', label: 'RFC', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: 'createdAt', label: 'Registro', alignRight: false },
  { id: '' },
];
export default function BusinessPage() {
  const { business, loadingInfo, businessData } = useBusiness();
  const [ open, setOpen ] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setOpen(false);
    businessData();
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Empresas
        </Typography>
        {
          loadingInfo ? 
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={153} height={36} />
            </Stack>
          : 
          <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nueva Empresa
          </Button>
        }
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card >
            {
              loadingInfo ? 
              <Stack spacing={1}>
                <Skeleton variant="rounded" width={1200} height={350} />
              </Stack>
              : 
              <TableCustomer tableHead={TABLE_HEAD} typeTable="business" refresh={onSubmit} data={business} />
            }
          </Card>
        </Grid>
      </Grid>
      <Modal title="Nueva Empresa" maxWidth={'xs'} open={open} onClose={handleCloseModal} >
        <BusinessForm onSubmit={onSubmit} />
      </Modal>
    </Container>
  )
}
