import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {}
    },
    reducers: {
        onUser: ( state, { payload } ) => {
            state.user = payload;
        },
    }
});

export const { onUser } = userSlice.actions;