/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Skeleton, Stack } from '@mui/material';
import Label from '../../../../components/label';
import { useCenterCostUser } from '../../../../hooks';

export default function UserCenterCost(props){
    const { user } = props;
    const { centerCostRelation, skeleting, FindRelationCenterCostUser } = useCenterCostUser();

    useEffect(() => {
        FindRelationCenterCostUser(user?.id);
    }, []);

  return (
    <>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
            Centros de costos relacionados
            </Typography>
        </Stack>
        {
            skeleting ? (
                <Grid container spacing={1}>
                    {
                        [1,2,3].map(
                            (item, index) => (
                                <Grid key={index} item xs={12} sm={4} md={4}>
                                    <Skeleton variant="rounded" width="100%" height={140} />
                                </Grid>
                            )
                        )
                    }
                </Grid>
            ):(
                <Grid container spacing={1}>
                    {
                        centerCostRelation.map(
                            (item, index) => (
                                <Grid key={index} item xs={12} sm={4} md={4}>
                                    <Card sx={{ minWidth: 275 }}>
                                        <CardContent>
                                            <Typography variant="body2">
                                                <Label style={{float: 'right'}} color={(item.center_cost?.status === 1 ? 'success':'error')}>
                                                    {(item.center_cost?.status === 1 ? 'Activo':'Inactivo')}
                                                </Label>
                                            </Typography>
                                            <Typography variant="h5" component="div">
                                                {item.center_cost.code}
                                            </Typography>
                                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                {item.center_cost.description}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        )
                    }
                </Grid>
            )
        }
    </>
  )
}
