import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PostBusinessUser, FindRelationUserBusiness, DeleteBusinessUser } from '../../service';

export const useBusinessUser = () =>{
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [ businessRelation, setBusinessRelation ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ skeleting, setSkeleting ] = useState(false);

  const RelationBusinessUser = async (body) => {
    setLoading(true);
    await PostBusinessUser(body).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        enqueueSnackbar(`${data.result}`, {
          variant: 'success',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const FindRelationBusinessUser = async (id) => {
    setSkeleting(true);
    await FindRelationUserBusiness(id).then(
      (result) => {
        const { data } = result;
        setBusinessRelation(data.result);
        setSkeleting(false);
      }
    ).catch(
      (error) => {
        setSkeleting(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  const DeleteRelationBusinessUser = async (id) => {
    await DeleteBusinessUser(id).then(
      (result) => {
        const { data } = result;
        setLoading(false);
        enqueueSnackbar(`${data.message}`, {
          variant: 'warning',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    ).catch(
      (error) => {
        setLoading(false);
        enqueueSnackbar(`${error.response.data.error}`, {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          action: (key) => (
            <IconButton onClick={() => { closeSnackbar(key) }}>
              <CloseIcon />
            </IconButton>
          )
        });
      }
    );
  }

  return {
    businessRelation,
    loading,
    skeleting,
    RelationBusinessUser,
    FindRelationBusinessUser,
    DeleteRelationBusinessUser,
  }
}

