export const PublicRoute = {
    LOGIN : 'login'
};

export const PrivateRoute = {
    HOME : 'inicio',
    USERS : 'usuarios',
    PROFILE : 'perfil',
    BUSINESS : 'empresas',
    CENTERCOST : 'centro-costo',
    REQUESTPENDING : 'solicitudes-por-autorizar',
    REQUESTPENDINGDETAIL : 'detalle',
    PURCHASEREQUEST : 'solicitud-compra',
    HISTORY : 'historico',
    DOCUMENTS : 'documentos',
    PURCHASEREQUESTDETAIL : 'detalle-solicitud',
    LOGGER : 'logs',
    UNI : '*',
}