/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react'
import { useSnackbar } from 'notistack';
import {
    styled,
    TextField,
    Button,
    Paper,
    Grid,
    Chip,
    MenuItem,
    Select,
    CircularProgress,
    IconButton,
    Skeleton,
    Stack,
    FormHelperText 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { useBusiness, useBusinessUser, useUserApprover, useUsers } from '../../../hooks';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function ApproverUserForm({ data }){
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { loading, skeleting, inApprovers, FindUserApprovers, RelationUserApprover, DeleteApproverUser } = useUserApprover();
    const [ approvers, setApprovers ] = useState([]);
    const [ usrApprovers, setUsrApprovers ] = useState([]);
    const [ selectedIndex, setSelectedIndex ] = useState(-1);
    const [ formData, setFormData ] = useState({user_id: data?.id, approval_id: 0});
    const [ btn, setBtn ] = useState('Añadir');
    const [ errors, setErrors ] = useState([]);

    const fetchApprovers = async () => {
        try {
            const { success, result } = await inApprovers();
            setApprovers(result);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchUserApprovers = async () => {
        try {
            const { success, result } = await FindUserApprovers(data?.id);
            setUsrApprovers(result);
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        fetchApprovers();
        fetchUserApprovers();
    }, []);

    const UserApproversSchema = yup.object().shape({
        approval_id: yup
            .number()
            .oneOf(approvers.map(item => item.id), 'Selecciona un aprobador')
            .required('El aprobador es requerido'),
    });

    const inputChange = (event) => {
        const { name, value } = event.target;
        let processedValue = value;
        if (name === 'approval_id') {
            processedValue = value;
        }

        setFormData((prevState) => ({
            ...prevState,
            [name]: processedValue,
        }));
        UserApproversSchema.validateAt(name, { [name]: processedValue })
        .then(() => {
            setErrors({ ...errors, [name]: "" });
        })
        .catch((err) => {
            setErrors({ ...errors, [name]: err.errors[0] });
        });
    };
    
    const addEmployesInUser = async () => {
        let exit = false;
        setBtn('Añadiendo');
        
        usrApprovers.forEach(item => {
            if (item.id === formData.user_id) exit = true;
        });

        if (exit) {
            setBtn('Añadir');
            enqueueSnackbar(`Aprobador, ya ha sido relacionado!`,{
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            return;
        }

        try {
            await UserApproversSchema.validate(formData, { abortEarly: false });
            if (!formData.approval_id) {
                setBtn('Añadir');
                return;
            }
            await RelationUserApprover(formData);
            await fetchUserApprovers();
            setBtn('Añadir');
            setFormData({user_id: data?.id, approval_id: 0});
        } catch (err) {
            enqueueSnackbar(`Formulario incompleto!`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                action: (key) => (
                    <IconButton onClick={() => { closeSnackbar(key) }}>
                      <CloseIcon />
                    </IconButton>
                )
            });
            if (err instanceof yup.ValidationError) {
                setErrors(err.inner.reduce((acc, curr) => {
                    acc[curr.path] = curr.message;
                    return acc;
                }, {}));
            }
            setBtn('Añadir');
        }
    };

    const employesDelete = async (id, index) => {
        setSelectedIndex(index);
        await DeleteApproverUser(id);
        await fetchUserApprovers();
        setSelectedIndex(-1);
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Item>
                    <Select 
                        autoFocus
                        id="approval_id"
                        name="approval_id"
                        size="small"
                        variant="outlined"
                        onChange={inputChange}
                        value={formData.approval_id}
                        error={Boolean(errors.approval_id)}
                        fullWidth
                    >
                        <MenuItem value={0}>Selecciona un aprovador</MenuItem>
                        {approvers?.map(
                            (item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name} {item.lastNameP} {item.lastNameM}
                                </MenuItem>
                            )
                        )}
                    </Select>
                    <FormHelperText error>{errors.approval_id}</FormHelperText>
                </Item>
            </Grid>
            <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                <Item>
                    <Button variant="contained" onClick={addEmployesInUser}>
                        {btn}
                        {loading && (
                            <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/>
                        )}
                    </Button>   
                </Item>
            </Grid>
            <Grid item xs={12}>
                {
                    skeleting ? 
                        <Stack spacing={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Skeleton variant="rounded" width={200} height={40} />
                        </Stack>
                    :
                    <>
                        {
                            usrApprovers.map(
                                (item, index) => (
                                    <Grid item xs={12} key={index} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        {selectedIndex === index ? (
                                            <Chip 
                                                variant="outlined" 
                                                label={`${item.approver.name} ${item.approver.lastNameP} ${item.approver.lastNameM}`}
                                                color="error"
                                                avatar={selectedIndex ? <CircularProgress size={20} color="inherit" style={{ marginLeft: '10px' }}/> : null} 
                                            />
                                        ) : (
                                            <Chip 
                                                variant="outlined" 
                                                style={{margin: '5px'}}
                                                label={`${item.approver.name} ${item.approver.lastNameP} ${item.approver.lastNameM}`}
                                                onDelete={() => employesDelete(item.id, index)}
                                            />
                                        )}
                                    </Grid>
                                )
                            )
                        }
                    </>
                }
            </Grid>
        </Grid>
    )
}
