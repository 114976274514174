import React from 'react'
// @mui
import { Container, Typography } from '@mui/material';

export default function HomePage() {
  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          ¡Hola, Bienvenido a OnCompras!
        </Typography>
      </Container>
    </>
  )
}
